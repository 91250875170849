import { Link, useNavigate } from 'react-router-dom';
import { Buttons, TextInput } from '../shared/components';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import './login.scss'
import { firebaseApp } from '../shared/firebase';
import { useState } from 'react';
import spinner from "../assets/animations/spinner.json";
import Lottie from 'lottie-react';

/**
 * admin@bamboobythelake.com
 * bamboo@254
 */


const RouteLogin = () => {
    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const login = () => {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log("user", user);
                navigate("/admin/dashboard");
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                alert(errorMessage)
                setLoading(false);
            });
    }
    return <div className="login-parent">
        <div className='left'>
            <div className='illustration'>
                <div className='il-inner'>
                    <div className='logo2'></div>
                    <div className='logo'></div>
                </div>
            </div>
        </div>
        <div className='right'>
            <div className='inner'>
                <div className='heading'>Login</div>
                <p>Enter your login details</p>
                <TextInput.Simple placeholder={"email"} onChange={(e) => { setEmail(e.target.value) }}/>
                <TextInput.Simple placeholder={"password"} onChange={(e) => { setPassword(e.target.value) }}  type="password"/>
                <div className='remember'>
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    <label for="vehicle1"> Remember browser</label>
                </div>

                <Buttons.Large text={"Sign in"} onClick={login} />

                <p>Don't have an account? <a>talk to support</a></p>
            </div>
            {loading && <div className="loading-anim">
                <Lottie animationData={spinner} loop={true} style={{ height: 240, width: 240 }} />
            </div>}
        </div>
    </div>
}
export default RouteLogin;