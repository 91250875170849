import { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia';
import { PiChatCenteredDots, PiFacebookLogoBold, PiInstagramLogoBold, PiPlayBold, PiPlayFill, PiTwitterLogoBold, PiYoutubeLogoBold } from 'react-icons/pi';
import { BambooDateRangeText, Buttons } from '../../shared/components';
import { SquareMenu } from './top/square-menu';
import { ChatWindow } from './top/chat-window';

const ChatBubble = ({ setChatShowing }) => {
    const [showing, setShowing] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowing(true);
            setTimeout(() => {
                setShowing(false);
            }, 3000);
        }, 1000);
    }, [])
    return <>
        <div className='chat-bubble' onClick={() => setChatShowing(true)}>
            <div className={`talk-to-us ${showing ? "scroll-show" : ""}`}><span>Talk To Us</span></div>

            <div className='ic'><PiChatCenteredDots /></div>

        </div>
    </>
}


const LandingTop = () => {
    const [rangeShowing, setRangeShowing] = useState(false);
    const [dateShowing, setDateShowing] = useState(false);
    const [videoShowing, setVideoShowing] = useState(false);
    const [chatShowing, setChatShowing] = useState(false);

    // const funk = (test) => {
    //     console.log("funk", test);
    //     setRangeShowing(test);
    // }
    console.log(rangeShowing);
    const scroll = (offset) => {
        const lp = document.getElementById("landing-parent");
        let pageHeight = document.body.scrollHeight;
        lp.scrollTo({
            top: pageHeight * offset,
            behavior: 'smooth',
        });
    }
    return <div className='top  snap-item'>
        <div className='left'></div>
        <div className='right'>
            <div className='bg theme-persist' onClick={() => {
                if (rangeShowing) setRangeShowing(false);
                if (dateShowing) setDateShowing(false);
            }}></div>
            <div className='content'>
                <div className='title'></div>
                {/* <div className='mobile-only'>
                    <div className='mobile-action-buttons'>
                        <Buttons.Large text={"Book Room"} />
                        <Buttons.Large text={"Watch Video"} theme={"black-but"} />
                    </div>

                </div> */}
                <div className='scroll-button' onClick={() => scroll(1)}><LiaLongArrowAltLeftSolid className='ic' /> Amenities</div>
                <SquareMenu rangeShowing={rangeShowing} setRangeShowing={setRangeShowing} dateShowing={dateShowing} setDateShowing={setDateShowing} />
                <ul className='social-buttons'>
                    <ChatBubble setChatShowing={setChatShowing} />
                    <li><a target='_blank' href="https://www.facebook.com/profile.php?id=61552047855204"><PiFacebookLogoBold /></a></li>
                    <li><a target='_blank' href="https://twitter.com/bamboobythelake"><PiTwitterLogoBold /></a></li>
                    <li><a target='_blank' href="https://www.instagram.com/bamboobythelakeresort"><PiInstagramLogoBold /></a></li>
                    <li><a target='_blank' href="https://www.youtube.com/@BambooByTheLake"><PiYoutubeLogoBold /></a></li>
                </ul>
            </div>
            <div className='play-bt' onClick={() => setVideoShowing(true)}><PiPlayFill /></div>
        </div>
        {
            videoShowing && <div className='modal' onClick={() => setVideoShowing(false)}>
                <iframe width="920" height="615"
                    src="https://www.youtube.com/embed/2AVP7aZmHD8?autoplay=1&mute=1">
                </iframe>
            </div>
        }
        {chatShowing && <ChatWindow setChatShowing={setChatShowing} />}
    </div>
}
export default LandingTop;