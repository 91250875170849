import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { firebaseApp } from "../../shared/firebase";
import { convertDateToReadable, convertReadableToDate, formatNumber } from "../../shared/generalMethodology";
import { taxRate } from "../../shared/constants";
import { BiEnvelope, BiSolidPhoneCall } from "react-icons/bi";

const AdminDashboard = () => {
    /**
     * last reservations
     * 
     */
    const [bookings, setBookings] = useState([]);
    const [incomingBookings, setIncomingBookings] = useState(0);
    const [checkouts, setCheckouts] = useState([]);
    const [tomorrowsCheckouts, setTomorrowsCheckouts] = useState(0);
    const [income, setIncome] = useState(0);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [tax, setTax] = useState(0);
    const [monthlyTax, setMonthlyTax] = useState(0);

    const firestore = getFirestore(firebaseApp);

    const stats = [
        { title: "Incoming Bookings", val: bookings.length, footer: `${bookings.length} bookings within 30 days` },
        { title: "Today's Checkouts", val: checkouts.length, footer: `There are ${tomorrowsCheckouts} checkouts tomorrow` },
        { title: "Total Income", val: `KES ${formatNumber(income)}`, footer: `Made KES ${formatNumber(monthlyIncome)} within 30 days` },
        { title: "Payable Taxes", val: `KES ${formatNumber(Math.round(income * taxRate))}`, footer: `KES ${formatNumber(Math.round(monthlyIncome * taxRate))} in 30 days at ${taxRate * 100}% rate` },
    ]
    useEffect(() => {

        (async () => {
            const querySnapshot = await getDocs(collection(firestore, "bookings"));
            const data = [];
            let income = 0;
            let income_monthly = 0;
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data().name}`);
                data.push(doc.data());
                //calculate income
                income += doc.data().payment_gross;
                //monthly
                if (true) {
                    income_monthly += doc.data().payment_gross;
                }
            });
            console.log(data);
            setBookings(data);
            setIncome(income);
            setMonthlyIncome(income_monthly)

        })()
    }, []);

    return <div className="dashboard-parent">
        <h1>Dashboard</h1>
        <div className="stats-parent">
            {stats.map((item,index) => {
                return <div className="stats-item" key={index}>
                    <div className="title">{item.title}</div>
                    <div className="value">{item.val}</div>
                    <div className="footer">{item.footer}</div>
                </div>
            })}
        </div>
        <h3>LATEST BOOKINGS</h3>
        <div className="bookings-parent">
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={bookings}
                pagination
                highlightOnHover
            />
        </div>

    </div>
}
export default AdminDashboard;
const customStyles = {
    rows: {
        style: {
            minHeight: '40px', // override the row height
            color: 'white'
        },
    },
    headCells: {
        style: {
            fontWeight: '700',
            fontSize: 12,
            // color: 'red'
        },
    },
    cells: {
        style: {
            fontSize: 14,
            // textTransform: "uppercase",
        },
    },
};
/**
 [
    {
        "checkout": {
            "seconds": 1698152131,
            "nanoseconds": 419000000
        },
        "phone": "254718870875",
        "payment_amount": 20000,
        "checkin": {
            "seconds": 1697287827,
            "nanoseconds": 778000000
        },
        "roomB": 1,
        "roomA": 2,
        "name": "Joseph Ndiritu",
        "email": "kimndiritu@gmail.com",
        "payment_status": "paid"
    }
]
 */
const columns = [

    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },


    {
        name: 'Checkin',
        selector: row => "" + convertDateToReadable(convertReadableToDate(parseInt(row.checkin.seconds) * 1000)),
        sortable: true,
    },
    {
        name: 'Checkout',
        selector: row => "" + convertDateToReadable(convertReadableToDate(parseInt(row.checkout.seconds) * 1000)),
        sortable: true,
    },
    {
        name: 'Rooms',
        selector: row => "A=" + row.roomA + ", B=" + row.roomB,
        // sortable: true,
    },
    {
        name: 'Payment',
        selector: row => "KES " + formatNumber(row.payment_amount),
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.payment_status ? row.payment_status : "pending",
        sortable: true,
    },
    {
        name: 'Action',
        selector: row => <div className="contact">
            <a href={`tel:${row.phone}`}><BiSolidPhoneCall /></a>
            <a href={`mailto:${row.email}`}><BiEnvelope /></a>
             </div>,
        sortable: true,
    },
    // {
    //     name: 'Email',
    //     selector: row => row.email,
    //     sortable: true,
    // },
];