import { useEffect, useState } from 'react';
import { FaArrowLeft, FaChevronDown, FaWindowClose } from 'react-icons/fa';
import { LiaLongArrowAltLeftSolid, LiaWindowClose } from 'react-icons/lia';
import { Link } from 'react-router-dom';
import { pageHeight } from '../../shared/constants';
import { BiMenu } from 'react-icons/bi';
import { MdClose } from "react-icons/md";

import './header.scss'
let height;

const LandingHeader = () => {
    const [selected, setSelected] = useState(0);
    const [menuShowing, setMenuShowing] = useState(false);
    const [lp, setLP] = useState();

    useEffect(() => {

        height = document.body.scrollHeight;
        const nav = document.getElementById('header');
        const mobi_nav = document.getElementById('mobile-header');
        const lparent = document.getElementById("landing-parent");
        setLP(lparent);

        let scrolled = false;
        lparent.onscroll = function () {
            // console.log("h", lparent.scrollTop);
            switch (true) {
                case (lparent.scrollTop < 30 && scrolled):
                    nav.classList.remove("header-scrolled");
                    mobi_nav.classList.remove("mobile-header-scrolled");
                    scrolled = false;
                    break;

                case (lparent.scrollTop < (height)):
                    setSelected(0);
                    break;
                case (lparent.scrollTop < ((height * 2 * 0.8))):
                    setSelected(1);
                    break;
                case (lparent.scrollTop < (height * 3 * 0.8)):
                    setSelected(2);
                    break;
                case (lparent.scrollTop < (height * 4 * 0.8)):
                    setSelected(3);
                    break;
                case (lparent.scrollTop < (height * 5 * 0.8)):
                    setSelected(4);
                    break;
            }
            if (lparent.scrollTop > 30 && !scrolled) {
                scrolled = true;
                nav.classList.add("header-scrolled");
                mobi_nav.classList.add("mobile-header-scrolled");
            }
        };
    }, []);
    const scroll = (offset) => {
        console.log("offset", offset);
        switch (offset) {
            case 0:
                lp.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                break;
            case 1:
                const element1 = document.getElementById('room-parent');
                lp.scroll({
                    top: element1.offsetTop,
                    behavior: 'smooth'
                });
                break;
            case 2:
                const element2 = document.getElementById('activities-parent');
                lp.scroll({
                    top: element2.offsetTop,
                    behavior: 'smooth'
                });
                break;
            // case 3:
            //     const element3 = document.getElementById('gallery-parent');
            //     lp.scroll({
            //         top: element3.offsetTop,
            //         behavior: 'smooth'
            //     });
            //     break;
            case 5:
                const element4 = document.getElementById('contact-parent');
                lp.scroll({
                    top: element4.offsetTop,
                    behavior: 'smooth'
                });
                break;
        }

    }
    return <>
        <div className='mobile-only'>
            <div className='mobile-header' id="mobile-header">
                <div className='logo'></div>
                <div className='flex-out'></div>
                <BiMenu size={50} onClick={() => setMenuShowing(true)} />
            </div>
        </div>
        <div className='header' id="header">

            <div className='logo' onClick={() => window.location.reload()}></div>



            <ul className='mid'>
                <li className={selected == 0 ? "active" : ""} onClick={() => scroll(0)}>Home</li>
                <li className={selected == 1 ? "active" : ""} onClick={() => scroll(1)}>Rooms</li>
                <li className={selected == 2 ? "active" : ""} onClick={() => scroll(2)}>Activities</li>
                <li className={selected == 3 ? "active" : ""} onClick={() => scroll(3)}>Gallery</li>
                <li className={selected == 4 ? "active" : ""} onClick={() => scroll(5)}>Contact</li>
            </ul>
            <Link to="/login">
                <div className='but'>Login</div>
            </Link>
        </div>
        {menuShowing
            &&
            <div className='mobile-menu'>
                <div className='close-ic'><MdClose size={50} onClick={() => setMenuShowing(false)} /></div>
                <ul>
                    <li className={selected == 0 ? "active" : ""} onClick={() => { scroll(0); setMenuShowing(false) }}>Home</li>
                    <li className={selected == 1 ? "active" : ""} onClick={() => { scroll(1); setMenuShowing(false) }}>Rooms</li>
                    <li className={selected == 2 ? "active" : ""} onClick={() => { scroll(2); setMenuShowing(false) }}>Activities</li>
                    {/* <li className={selected == 3 ? "active" : ""} onClick={() => { scroll(3); setMenuShowing(false) }}>Gallery</li> */}
                    <li className={selected == 4 ? "active" : ""} onClick={() => { scroll(5); setMenuShowing(false) }}>Contact</li>
                </ul>

            </div>
        }
    </>

}
export default LandingHeader;