import { useEffect, useState } from 'react';
import { Buttons, TextInput } from '../shared/components';
import './booking.scss'
import BookingHeader from './booking/header';
import { GetRandomStringOfLength, convertDateToReadable, formatNumber, getNights, getPostDate, unhyphenate } from '../shared/generalMethodology';
import { FaCheck, FaShieldAlt } from 'react-icons/fa';
import { RiCalendarLine, RiVipCrown2Line } from 'react-icons/ri';
import { MdOutlineCalendarMonth, MdOutlineMeetingRoom } from 'react-icons/md';
import Lottie from "lottie-react";
import hotel from "../assets/animations/hotel.json";
import spinner from "../assets/animations/spinner.json";
import phone from "../assets/animations/phone.json";
import thumbs_up from "../assets/animations/thumbs_up.json";
import { roomAprice, roomBprice, taxRate } from '../shared/constants';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';
import { firebaseApp } from '../shared/firebase';


const RouteBooking = () => {
    const firestore = getFirestore(firebaseApp);


    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [paymentSent, setPaymentSent] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentThankYou, setPaymentThankYou] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");


    const [startDate, setStartDate] = useState(convertDateToReadable(new Date()));
    const [endDate, setEndDate] = useState(convertDateToReadable(getPostDate(new Date(), 1)));
    const [roomA, setRoomA] = useState(1);
    const [roomB, setRoomB] = useState(0);
    const [nights, setNights] = useState(0);
    useEffect(() => {
        setTimeout(() => { setLoading1(false) }, 3000);
        const ref = window.location.href;
        if (ref.split('booking?').length === 1) return window.location = "/";
        if (ref.split('booking?')[1].length === 0) return window.location = "/";
        const vars = window.location.href.split("booking?")[1].split("&");
        console.log(vars);
        let start,end;
        vars.map((item) => {
            var title = item.split("=")[0];
            var value = item.split("=")[1]
            switch (title) {
                case "checkin":
                    setStartDate(unhyphenate(value));
                    start=unhyphenate(value);
                    break;
                case "checkout":
                    setEndDate(unhyphenate(value));
                    end=unhyphenate(value);
                    break;
                case "A":
                    setRoomA(value);
                    break;
                case "B":
                    setRoomB(value);
                    break;
                case "bId":
                    setPaymentSent(true);
                    break;
            }
        });
        const nights=getNights(start, end)
        setNights(nights);
    }, []);


    const initPayment = async () => {
        var subtotal = (roomA * roomAprice * nights) + (roomB * roomBprice * nights);
        var tax = Math.ceil(subtotal * taxRate);

        if (firstName.length < 1
            ||
            lastName.length < 1
            ||
            email.length < 1
            ||
            phoneNumber.length < 1
        ) {
            return alert("fill in all fields to proceed");
        }
        setLoading2(true);
        try {
            const docRef = await addDoc(collection(firestore, "bookings"), {
                checkin: new Date(startDate).toDateString(),
                checkout: new Date(endDate).toDateString(),
                email,
                name: firstName + " " + lastName,
                payment_amount: (subtotal + tax),
                payment_gross: subtotal,
                // payment_status:"sent" ---> read only
                phone: phoneNumber,
                roomA,
                roomB,
                method: "mpesa",
                initiated: new Date().toISOString()
            });
            // const data = {
            //     "company": "bamboo",
            //     "system": "js",
            //     "phone_number": phoneNumber,
            //     "reference": "abc123",
            //     "recepient": "test_recepient",
            //     "short_code": "4123893",
            //     "pass_key": "df155165d79b8a3b25a06277aa7694b02cd25c1f9c911cc16998dd8c2eb7a674",
            //     "consumer_key": "sZvG2JvxMYqowGy6JEsa2mrSpuAcYbdu",
            //     "consumer_secret": "s2r2rwQaePX9kMsq",
            //     "party_b": "4123893",
            //     "amount": 100,
            // };
            console.log("Document written with ID: ", docRef.id);
            setLoading2(false);
            setPaymentSent(true);
            if (!window.location.href.includes("bId")) window.history.replaceState(null, null, (window.location.href + "&bId=" + docRef.id));
        } catch (e) {
            console.error("Error adding document: ", e);
            alert(e);
        }


        // setTimeout(() => {
        //     
        // }, 3000);
    }

    var subtotal = (roomA * roomAprice * nights) + (roomB * roomBprice * nights);
    var tax = Math.ceil(subtotal * taxRate);

    if (loading1) {
        return <div className='booking-parent'>
            <BookingHeader />
            <div className="loading-anim">
                <Lottie animationData={hotel} loop={true} style={{ height: 270, width: 270 }} />
                <p>loading booking info</p>
            </div>
        </div>
    }
    if (loading2) {
        return <div className='booking-parent'>
            <BookingHeader />
            <div className="loading-anim">
                <Lottie animationData={spinner} loop={true} style={{ height: 270, width: 270 }} />
                <h1>initiating payment</h1>
                <p className='sub'>...please wait a sec...</p>
            </div>
        </div>
    }
    if (paymentSuccess) {
        return <div className='booking-parent'>
            <BookingHeader />
            <div className='success-parent'>
                <div className='success-inner'>
                    {/* <div className='mpesa-logo'></div> */}
                    <Lottie className='animation' animationData={thumbs_up} loop={false} style={{ height: 240, width: 240, margin: 'auto' }} />
                    <h1>Booking Successful!</h1>
                    <p>Kindly check your phone for the payment prompt. You will be required to enter your m-pesa pin to
                        to complete the transaction. Alternatively, you can use the details below to pay.</p>

                    <div className='row'>
                        <Buttons.Large text={"Go Back"} onClick={() => window.location.reload()} />
                    </div>
                </div>
            </div>
        </div>
    }
    if (paymentThankYou) {
        return <div className='booking-parent'>
            <BookingHeader />
            <div className='success-parent'>
                <div className='success-inner'>
                    {/* <div className='mpesa-logo'></div> */}
                    <Lottie className='animation' animationData={thumbs_up} loop={false} style={{ height: 240, width: 240, margin: 'auto' }} />
                    <h1>Thank you!</h1>
                    <p>We are looking forward to having you. Safe travels!</p>

                    <div className='row'>
                        <Buttons.Large text={"Go Back"} onClick={() => window.location="/"} />
                    </div>
                </div>
            </div>
        </div>
    }
    if (paymentSent) {
        return <div className='booking-parent'>
            <BookingHeader />
            <div className='mpesa-parent'>
                <div className='left'>
                    <div className="phone-anim">
                        <Lottie className='animation' animationData={phone} loop={false} />
                        <h1>Payment Initiated</h1>
                    </div>
                </div>
                <div className='right'>
                    <div className='right-inner'>
                        <div className='mpesa-logo'></div>
                        <h1>Waiting for payment</h1>
                        <p>Request sent. Kindly check your phone for the payment prompt. You will be required to enter your m-pesa pin to
                            complete the M-pesa transaction. Alternatively, you can use the details below to pay.</p>
                        <div className='row'>
                            <div className='title'>Paybill</div>
                            <div className='value'>522533</div>
                        </div>
                        <div className='row'>
                            <div className='title'>Account Number</div>
                            <div className='value'>7697710</div>
                        </div>
                        <div className='row'>
                            <div className='title'>Amount</div>
                            <div className='value'><span>KES</span> {formatNumber(subtotal + tax)}</div>
                        </div>
                        <p>This page should automatically reload. If you have paid and nothing happens, click the button below
                            to cconfirm you have paid.</p>
                        <div className='row'>
                            <Buttons.Large text={"cancel"} theme={"white-but"} onClick={() => {
                                window.history.replaceState('', '', window.location.href.split("&bId")[0])
                                window.location.reload()
                            }} />
                            <Buttons.Large text={"I Have Paid"} onClick={() => setPaymentThankYou(true)} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    }


    return <div className='booking-parent'>
        <BookingHeader />
        <div className='booking-inner'>
            <div className='left'>
                <div className='row'>
                    <h1>Payment Information </h1>
                </div>
                <div className='row'>
                    <TextInput.Outlined placeholder={"First Name"} onChange={(e) => setFirstName(e.target.value)} />
                    <TextInput.Outlined placeholder={"Last Name"} onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className='row'>
                    <TextInput.Outlined placeholder={"Phone Number"} onChange={(e) => setPhoneNumber(e.target.value)} />
                    <TextInput.Outlined placeholder={"Email"} onChange={(e) => setEmail(e.target.value)} />
                </div>
                {/* <h3>Cancellation Policy </h3> */}

                <div className='statement'>
                    <div className='title'><FaShieldAlt /> YOUR SECURITY IS IMPORTANT TO US</div>
                    <ul>
                        <li>
                            <FaCheck /><div>Your data is fully secure</div>
                        </li>
                        <li>
                            <FaCheck /><div>We use trusted payment gateways</div>
                        </li>
                        <li>
                            <FaCheck /><div>We don't share your data to third-parties</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='right'>
                <div className='right-inner'>
                    <div className='row'>
                        <h2>Booking summary </h2>
                    </div>
                    <div className='row'>
                        <div className='title'><MdOutlineCalendarMonth /> Checkin Date</div>
                        <div className='value'>{startDate}</div>
                    </div>
                    <div className='row'>
                        <div className='title'><MdOutlineCalendarMonth />Checkout Date</div>
                        <div className='value'>{endDate}</div>
                    </div>
                    {roomA > 0 && <div className='row'>
                        <div className='title'><MdOutlineMeetingRoom />Excecutive Suite</div>
                        <div className='value'>{roomA} x KES {formatNumber(roomAprice)} x {nights} night(s)</div>
                    </div>}

                    {roomB > 0 && <div className='row'>
                        <div className='title'><RiVipCrown2Line /> Kings Suite</div>
                        <div className='value'>{roomB} x KES {formatNumber(roomBprice)} x {nights} night(s)</div>
                    </div>
                    }
                    <br />
                    <br />
                    <div className='row'>
                        <div className='title'>Subtotal</div>
                        <div className='value'><span>KES</span> {formatNumber(subtotal)}.00</div>
                    </div>
                    <div className='row'>
                        <div className='title'>Tax({taxRate * 100}% VAT)</div>
                        <div className='value'><span>KES</span> {formatNumber(tax)}.00</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='title'>Total To Pay</div>
                        <div className='value highlighted'><span>KES</span> {formatNumber(subtotal + tax)}.00</div>
                    </div>


                    <div className='row'>
                        <Buttons.Large text={"Paypal/Card"} theme={"transparent-but"} />
                        {/* </div>
                    <div className='row'> */}
                        <Buttons.Large onClick={initPayment} text={"Pay with Mpesa"} />

                    </div>
                </div>
                <div className='agreement'>
                    <p>By proceeding, you agree to our <a>terms and conditions</a> and you have read and understood our <a>cancellation policy</a> </p>
                </div>
            </div>
        </div>
    </div>
}
export default RouteBooking;