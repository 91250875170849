import React, { useEffect } from "react"
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
const pictures = [
    {
        original: require('../../../assets/interior/k1.jpeg'),
        thumbnail: require('../../../assets/interior/k1.jpeg')
    },
    {
        original: require('../../../assets/interior/k2.jpeg'),
        thumbnail: require('../../../assets/interior/k2.jpeg')
    },
    {
        original: require('../../../assets/interior/k3.jpeg'),
        thumbnail: require('../../../assets/interior/k3.jpeg')
    },
    {
        original: require('../../../assets/interior/k4.jpeg'),
        thumbnail: require('../../../assets/interior/k4.jpeg')
    },
    
]


const RoomCarousel = () => {

    return <div className="room-carousel">

        <ImageGallery
            autoPlay={true}
            items={pictures}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            renderItem={({ original }) => {
                // console.log("or", original);
                return <div className="room-item" style={{ backgroundImage: `url(${original})` }}>
                    {/* <div className="glass">
                            <img src={original} />
                        </div> */}
                </div>
            }}
            renderThumbInner={() => <div className="room-thumb-item"></div>}
        />
    </div>
}
export default RoomCarousel;