import './landing.scss'
import LandingHeader from './landing/header';
import LandingTop from './landing/top';
import LandingRooms from './landing/rooms';
import LandingLocation from './landing/location';
import { useEffect } from 'react';
import LandingActivities from './landing/activities';
import LandingGalery from './landing/galery';
import LandingContact from './landing/contact';
import { LandingFooter } from './landing/footer';

const RouteLanding = () => {
    return <div className="landing-parent" id="landing-parent">
        <LandingHeader />
        <LandingTop />
        <LandingRooms />
        <LandingActivities />
        <LandingGalery/>
        <LandingContact />
        <LandingFooter/>
    </div>
}
export default RouteLanding;