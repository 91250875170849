import { BiSolidCircle, BiSolidCircleHalf } from "react-icons/bi";

const AdminMessaging = () => {
    return <div className="messaging-parent">
        <div className="m-left">
            <div className="title">
                <div>Chats</div>
                <div><BiSolidCircle/></div>
            </div>
        </div>
        <div className="m-right">
            <div className="empty-state">
                <div className="illustration"></div>
                <h2>Bamboo Messenger</h2>
                <p>Select a message from the chats to open the full thread</p>
            </div>
        </div>
    </div>
}
export default AdminMessaging;