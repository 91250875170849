import { useEffect, useState } from 'react';
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia';

let height;

const BookingHeader = () => {
    const [selected, setSelected] = useState(0);
    const [lp, setLP] = useState();

    // useEffect(() => {
    //     height = document.body.scrollHeight;
    //     const nav = document.getElementById('header');
    //     const lparent = document.getElementById("landing-parent");
    //     setLP(lparent);

    //     let scrolled = false;
    //     lparent.onscroll = function () {
    //         // console.log("h", lparent.scrollTop);
    //         switch (true) {
    //             case (lparent.scrollTop < 30 && scrolled):
    //                 nav.classList.remove("header-scrolled");
    //                 scrolled = false;
    //                 break;

    //             case (lparent.scrollTop < (height)):
    //                 setSelected(0);
    //                 break;
    //             case (lparent.scrollTop < ((height * 2 * 0.8))):
    //                 setSelected(1);
    //                 break;
    //             case (lparent.scrollTop < (height * 3 * 0.8)):
    //                 setSelected(2);
    //                 break;
    //             case (lparent.scrollTop < (height * 4 * 0.8)):
    //                 setSelected(3);
    //                 break;
    //             case (lparent.scrollTop < (height * 5 * 0.8)):
    //                 setSelected(4);
    //                 break;
    //         }
    //         if (lparent.scrollTop > 30 && !scrolled) {
    //             scrolled = true;
    //             nav.classList.add("header-scrolled");
    //         }
    //     };
    // }, []);
    const scroll = (offset) => {
        console.log("offset", height);
        lp.scrollTo({
            top: height * offset,
            behavior: 'smooth',
        });
    }
    return <div className='header' id="header">
        <div className='logo'  onClick={()=>window.location="/"}></div>

        <ul className='mid'>
            <div className='mid-logo'></div>
            {/* <li className={selected == 0 ? "active" : ""} onClick={() => scroll(0)}>Home</li>
            <li className={selected == 1 ? "active" : ""} onClick={() => scroll(1)}>Rooms</li>
            <li className={selected == 2 ? "active" : ""} onClick={() => scroll(2)}>Activities</li>
            <li className={selected == 3 ? "active" : ""} onClick={() => scroll(3)}>Gallery</li>
            <li className={selected == 4 ? "active" : ""} onClick={() => scroll(5)}>Contact</li> */}
        </ul>
        <div className='booking-header-link'>policy</div>
    </div>
}
export default BookingHeader;