import { useCallback, useEffect, useState } from "react";
import { firebaseApp } from "../../shared/firebase";
import { useDropzone } from 'react-dropzone'
import imageCompression from 'browser-image-compression';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, getFirestore, Timestamp, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import spinner from "../../assets/animations/spinner.json";

import { GetRandomStringOfLength } from "../../shared/generalMethodology";
import Lottie from "lottie-react";
import { RiDeleteBinLine } from "react-icons/ri";



const AdminGalery = () => {
    const [files, setFiles] = useState([]);
    const [pictures, setPictures] = useState([]);
    const firestore = getFirestore(firebaseApp);
    const storage = getStorage(firebaseApp);

    const [loading, setLoading] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log("ehee", acceptedFiles);
        // setFiles(acceptedFiles);
        uploadImage(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const getImages = async () => {
        setLoading(true);
        const querySnapshot = await getDocs(collection(firestore, "gallery"));
        const data = [];
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data().name}`);
            data.push({...doc.data(),docRefId:doc.id});

        });
        console.log(data);
        setPictures(data);
        setLoading(false);
    }
    useEffect(() => {
        getImages();
    }, []);

    const uploadImage = async (files) => {
        let docRefId = "";//docRef.id;
        console.log(0)
        const pid = GetRandomStringOfLength(12);
        setLoading(true);
        var imageFile = files[0];
        var localLink = "";
        var reader = new FileReader();
        var url = reader.readAsDataURL(imageFile);
        let preview;
        reader.onloadend = function (e) {
            console.log(1);
            preview = [reader.result];

        }.bind(this);
        //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        var extension = "";
        var filename = imageFile.name.toLowerCase();
        if (filename.includes("png")) {
            extension = ".png"
        }
        if (filename.includes("jpg")) {
            extension = ".jpg"
        }
        const thumb_options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 250,
            useWebWorker: true,
            fileType: "image/png"
        }
        const main_options = {
            maxSizeMB: 0.4,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/jpeg"
        }
        const compressedThumb = await imageCompression(imageFile, thumb_options);
        console.log(`compressed thumb size ${compressedThumb.size / 1024 / 1024} MB`);
        const compressedMain = await imageCompression(imageFile, main_options);
        console.log(`compressed Main size ${compressedThumb.size / 1024 / 1024} MB`);


        try {
            const docRef = await addDoc(collection(firestore, "gallery"), {
                time: Timestamp.fromDate(new Date()),
                pid
            });
            console.log("Document written with ID: ", docRef.id);
            docRefId = docRef.id;
        } catch (e) {
            console.error("Error adding document: ", e);
            alert(e);
        }


        const thumbRef = ref(storage, `profile_pics/${pid}_thumb.png`);
        const mainRef = ref(storage, `profile_pics/${pid}_main.png`);
        // 'file' comes from the Blob or File API
        uploadBytes(thumbRef, compressedThumb).then(async (snapshot) => {
            console.log('Uploaded thumb file!', snapshot);
            getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                console.log('Thumb File available at', downloadURL);
                await updateDoc(doc(firestore, "gallery", docRefId), {
                    thumb: downloadURL
                });
            });
            uploadBytes(mainRef, compressedMain).then((snapshot2) => {
                console.log('Uploaded  main file!', snapshot2);
                getDownloadURL(snapshot2.ref).then(async (downloadURL) => {
                    console.log('Main File available at', downloadURL);
                    await updateDoc(doc(firestore, "gallery", docRefId), {
                        main: downloadURL
                    });
                    setLoading(false);
                    getImages();
                });
            });
        });


    }

    return <div className="galery-parent">
        <div  {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="drop-region">
            <div className="drop-inner">
                {isDragActive ? "Drop file here" : "Drag or pick an image to add to the galery"}
            </div>
        </div>
        </div>
        
        <div className="pictures-parent">
            {pictures.map((item,index) => {
                return <div key={index} className="pictures-item" style={{ backgroundImage: `url(${item.main})` }}>
                    <div className="delete-button" onClick={async () => {
                        setLoading(true);
                        console.log(item);
                        await deleteDoc(doc(firestore, "gallery", item.docRefId));
                        getImages();
                    }}><RiDeleteBinLine /></div>
                </div>
            })}
        </div>
        {loading && <div className="loading-animation"><div><Lottie animationData={spinner} loop={true} style={{ height: 200, width: 200 }} /></div></div>}
    </div>;

}
export default AdminGalery;