import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCy9KtO6KxsAvUlzHhbJBdEVblT6xY_vf0",
    authDomain: "bamboobythelakeapp.firebaseapp.com",
    projectId: "bamboobythelakeapp",
    storageBucket: "bamboobythelakeapp.appspot.com",
    messagingSenderId: "437438747415",
    appId: "1:437438747415:web:5a7e88e1f9477e6625dd52",
    measurementId: "G-SYBMP8SZVD"
};

export const firebaseApp = initializeApp(firebaseConfig);