import RouteBooking from "./routes/booking";
import RouteLanding from "./routes/landing";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RouteLogin from "./routes/login";
import RouteAdmin from "./routes/admin";
import AdminDashboard from "./routes/admin/dashboard";
import AdminGalery from "./routes/admin/galery";
import AdminMessaging from "./routes/admin/messaging";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RouteLanding />,
    // children: [
    //   {
    //     path: "signin",
    //     element: <SubRouteSignin />,
    //   }
    // ],
  },
  {
    path: '/booking',
    element: <RouteBooking />,
  },
  {
    path: '/login',
    element: <RouteLogin />,
  },
  {
    path: '/admin',
    element: <RouteAdmin />,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "messaging",
        element: <AdminMessaging />,
      },
      {
        path: "gallery",
        element: <AdminGalery />,
      }
    ],
  }
]);


function App() {
  return (
    <div className="App">
      {/* router will come here */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;




/**
 * 
 * NB: REVIEWS
 * 
 */