import { Buttons, TextInput } from "../../../shared/components"
import { BiChat, BiCloset } from 'react-icons/bi';
import { RiCloseLine } from 'react-icons/ri';

const Start = ({setChatShowing}) => {
    return <div className="start-screen">
        {/* <div className="illustration"></div> */}
        <div className="row">
            <BiChat size={70} />
        </div>
        <h1>Talk To Someone</h1>
        <div className="row">
            <TextInput.Grey placeholder={"Name"} />
            <TextInput.Grey placeholder={"Email"} />
        </div>
        <div className="row">
            <TextInput.Grey placeholder={"Phone Number"} />
        </div>
        <div className="row">
            <TextInput.GreyArea placeholder={"Your Message"} height={80} />
        </div>
        <div className="but-parent">
            <Buttons.Large text={"Cancel"} theme={"white-but"} style={{ paddingTop: 20, paddingBottom: 20 }} onClick={()=>setChatShowing(false)}/>
            <Buttons.Large text={"Send Message"} style={{ paddingTop: 20, paddingBottom: 20 }} />
        </div>
    </div>
}

export const ChatWindow = ({setChatShowing}) => {
    return <div className="chat-window-parent">
        <div className="chat-window">
            <div className="close-but" onClick={()=>setChatShowing(false)}><div><RiCloseLine /></div></div>
            <Start  setChatShowing={setChatShowing}/>
        </div>
    </div>
}