import './components.scss'
export const TextInput = {
    Outlined: ({ placeholder, onChange, type }) => {
        return <div className="component">
            <div className="text-input-outlined">
                <div className="title">{placeholder ? placeholder : ''}</div>
                <input placeholder={placeholder ? placeholder : ''}
                    onChange={onChange ? onChange : () => { }}
                    type={type ? type : 'text'} />
            </div>
        </div>
    },
    OutlinedArea: ({ placeholder, onChange, type }) => {
        return <div className="component">
            <div className="text-input-outlined">
                <div className="title">{placeholder ? placeholder : ''}</div>
                <textarea placeholder={placeholder ? 'Enter Your ' + placeholder : ''}
                    onChange={onChange ? onChange : () => { }}
                    type={type ? type : 'text'} />
            </div>
        </div>
    },
    Simple: ({ placeholder, onChange, type }) => {
        return <div className="component">
            <div className="text-input-simple">
                <input placeholder={placeholder ? 'Enter Your ' + placeholder : ''}
                    onChange={onChange ? onChange : () => { }}
                    type={type ? type : 'text'}
                />
            </div>
        </div>
    },
    SimpleArea: ({ placeholder, onChange, type }) => {
        return <div className="component">
            <div className="text-input-simple">
                <textarea placeholder={placeholder ? 'Enter Your ' + placeholder : ''}
                    onChange={onChange ? onChange : () => { }}
                    type={type ? type : 'text'}
                />
            </div>
        </div>
    },
    Grey: ({ placeholder, onChange, type }) => {
        return <div className="component">
            <div className="text-input-grey">
                <input placeholder={placeholder ?  placeholder : ''}
                    onChange={onChange ? onChange : () => { }}
                    type={type ? type : 'text'}
                />
            </div>
        </div>
    },
    GreyArea: ({ placeholder, onChange, type,height }) => {
        return <div className="component">
            <div className="text-input-grey">
                <textarea style={height?{height}:{}} placeholder={placeholder ?  placeholder : ''}
                    onChange={onChange ? onChange : () => { }}
                    type={type ? type : 'text'}
                />
            </div>
        </div>
    }
}
export const Buttons = {
    Large: ({ text, theme, onClick,style }) => {
        return <div className="component" onClick={onClick ? onClick : () => { }}>
            <a className={`large-but ${theme ? theme : ''}`}  style={style?style:{}}>{text}</a>
        </div>
    }
}
