export const GetRandomNumberBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export const GetRandomStringOfLength = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
export const convertDateToReadable = (date) => {
    const today = date;
    var monthsInYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${today.getDate()} ${monthsInYear[today.getMonth()]} ${today.getUTCFullYear()}`
}
export const convertReadableToDate = (date) => {
    return new Date(date)
}
// export const getNowReadable = () => {
// }
export const getPostDate = (originDate, numDays) => {
    let today = originDate;
    today.setDate(today.getDate() + numDays);
    console.log(today)
    return today;
}
export const hyphenate = (string) => {
    return string.split(' ').join('-')
}
export const unhyphenate = (string) => {
    return string.split('-').join(' ')
}

export const getNights = (d1, d2) => {
    var date1 = new Date(d1);
    var date2 = new Date(d2);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return numberOfNights;
}

export const formatNumber = (number) => {
    if (typeof Intl === "undefined" || !Intl.NumberFormat) {
      console.log("This browser doesn't support Intl.NumberFormat");
      return number;
    } else {
      const nFormat = new Intl.NumberFormat();
      const count = 42000000;
      return nFormat.format(number);
    }
  }
