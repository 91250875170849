import React, { useEffect, useState } from "react"
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { addDoc, collection, getFirestore, Timestamp, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { firebaseApp } from "../../shared/firebase";

const LandingGalery = () => {
    const [pictures, setPictures] = useState([]);
    const firestore = getFirestore(firebaseApp);
    const [loading, setLoading] = useState(false);

    const getImages = async () => {
        setLoading(true);
        const querySnapshot = await getDocs(collection(firestore, "gallery"));
        const data = [];
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data().name}`);
            data.push({ ...doc.data(), thumbnail: doc.data().thumb });
        });
        console.log(data);
        setPictures(data);
        setLoading(false);
    }
    useEffect(() => {
        getImages();
    }, []);
    return <>
        <section className='gallery-parent snap-item' id="gallery-parent">
            <ImageGallery autoPlay={true}
                items={pictures}
                renderItem={({ main }) => {
                    // console.log("or", original);
                    return <div className="carousel-item">
                        <div className="glass">
                            <img src={main} />
                        </div>
                    </div>
                }}
                renderThumbInner={({ thumbnail }) => {
                    return <div className="thumb-item" style={{ backgroundImage: `url(${thumbnail})` }}>
                    </div>
                }}
            />
        </section>
    </>
}
export default LandingGalery;