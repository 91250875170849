import { useEffect, useState } from "react";
import { LiaChevronLeftSolid, LiaChevronRightSolid } from "react-icons/lia";
import { PiBathtub, PiBed, PiCircle, PiCircleFill, PiScreencast, PiTelevision, PiTelevisionThin, PiToilet, PiWifiHigh, PiWifiHighBold } from "react-icons/pi";
import { CgScreen, CgSmartHomeRefrigerator } from "react-icons/cg";
import RoomCarousel from "./room/room-carousel";
import { formatNumber } from "../../shared/generalMethodology";
import { roomAprice, roomBprice } from "../../shared/constants";

const LandingRooms = () => {
    const rooms = [
        {
            type: "executive suite",
            description: "Step into the Standard Suite, and you'll immediately be greeted by a breath of fresh air. Inside, you'll find a plush king-sized bed, a lavish entertainment set readily fitted with DSTV, and a modern bath with all the amenities you need for a luxurious stay. The Standard Suite is the ultimate choice for couples seeking a romantic getaway or anyone yearning for a room with a view that soothes the soul.",
            amenities: { single_bed: 1, bathroom: 1, bathroom: 1, dstv: 1 },
            images: [
                require('../../assets/interior/k1.jpeg'),
                require('../../assets/interior/k2.jpeg'),
                require('../../assets/interior/k3.jpeg'),
                require('../../assets/interior/k4.jpeg'),
            ]
        },
        {
            type: "king's suite",
            description: "For those seeking adventure, Lake Victoria offers a plethora of activities, from fishing and boat trips to water sports like kayaking and windsurfing. Explore the rich biodiversity of the lake with guided eco-tours or relax by the pool with a refreshing cocktail in hand. There's something for everyone, whether you're an adrenaline junkie or a nature lover.            ",
            amenities: { double_beds: 1, bathroom: 1, bathroom: 1, dstv: 1 },
            images: [
                require('../../assets/interior/k1.jpeg'),
                require('../../assets/interior/k2.jpeg'),
                require('../../assets/interior/k3.jpeg'),
                require('../../assets/interior/k4.jpeg'),
            ]
        }
    ]
    useEffect(() => {
        // const outsider = document.getElementById("room-parent");
        // outsider.scrollTo(outsider.scrollLeft + 100, 0);

        // outsider.scrollBy({
        //     left: -300,
        //     behavior: 'smooth'
        // });
        // const rooms = document.getElementById('room-parent');

        // rooms.onscroll = function () {
        //     console.log("dx", document.body.scroll);

        // };
    }, []);
    const [selected, setSelected] = useState(0);

    const scroll = (porality) => {
        if(porality){
            const outsider = document.getElementById("room-parent");

            outsider.classList.add("rooms-scrolled")
        }else{
            const outsider = document.getElementById("room-parent");

            outsider.classList.remove("rooms-scrolled")
        }
     
    }


    return <div className="room-parent snap-item" id="room-parent">
        <div className='rooms'>
            {rooms.map((item, index) => (
                <div className="room" key={index}>
                    <div className="left">
                        <h1>{item.type}</h1>
                        <div className="price">KES {index === 0 ? formatNumber(roomAprice) : formatNumber(roomBprice)} <span>per room</span></div>
                        <p>
                            Step into the Sunset Suite, and you'll immediately be greeted by breathtaking panoramas of the Sunset. Inside, you'll find a plush king-sized bed, a well-appointed sitting area, and a modern bathroom with all the amenities you need for a luxurious stay. The Sunset Suite is the ultimate choice for couples seeking a romantic getaway or anyone yearning for a room with a view that soothes the soul.
                        </p>

                        <h4>Most Popular Facilities</h4>
                        <div className="amenities">
                            {item.amenities.dstv && <div><PiToilet size={25} /><span>Toilet</span></div>}
                            {item.amenities.bathroom && <div><PiBathtub size={25} /><span>Tub</span></div>}
                            {item.amenities.single_bed && <div><PiBed size={25} /><span>Single bed</span></div>}
                            {item.amenities.double_beds && <div><PiBed size={25} /><span>Double bed</span></div>}
                            {item.amenities.dstv && <div><CgScreen size={25} /><span>DsTv</span></div>}
                            {item.amenities.dstv && <div><PiWifiHighBold size={25} /><span>Free Wifi</span></div>}
                            {item.amenities.dstv && <div><CgSmartHomeRefrigerator size={25} /><span>Fridge</span></div>}
                        </div>
                        {/* <div className="but">More Info</div> */}
                    </div>
                    <div className="right">
                        <RoomCarousel />
                        {/* <div className="graphic">
                            <div className="f1" style={{backgroundImage:`url(${item?.images[0]})`}}></div>
                            <div className="f2" style={{backgroundImage:`url(${item?.images[1]})`}}></div>
                            <div className="f3" style={{backgroundImage:`url(${item?.images[2]})`}}></div>
                            <div className="f4" style={{backgroundImage:`url(${item?.images[3]})`}}></div>
                        </div> */}
                    </div>
                </div>
            ))}


        </div>
        {/* 
         */}
        <div className="arrow-left" onClick={() => scroll(false)}><LiaChevronLeftSolid /></div>
        <div className="arrow-right" onClick={() => scroll(true)}><LiaChevronRightSolid /></div>
        {/* <div className="selector-dots">
            {rooms.map((item, index) => (<div key={index}>
                {index === selected ? <PiCircleFill /> : <PiCircle />}
            </div>
            ))}
        </div> */}
    </div>

}
export default LandingRooms;