/**
   * Bird Watching
   * fishing
   * camping
   * jetskiing
   * boat rides.
   * game drive - Ruma national park.
   * bonfire
   * photoshoot
   * romantic gateway
   * nature walk
   */
const LandingActivities = () => {
    const activities = [
        {
            title: "Fishing",
            desc: "Indulge in the art of fishing with an abundance of aquatic treasures",
            image: require('../../assets/experiences/4.jpeg'),
        },
        {
            title: "Bird Watching",
            desc: "Bird Watching at Bamboo by the Lake Resort: Nature's Symphony on Display",
            image:require('../../assets/experiences/3.jpeg'),
        },
        {
            title: "Camping",
            desc: "A chance to embrace the great outdoors",
            image:require('../../assets/experiences/2.jpeg'),
        },
        {
            title: "jetskiing",
            desc: "Ride the waves of adventure and beautiful breeze",
            image: require('../../assets/experiences/1.jpeg'),
        },
        // {
        //     title: "boat rides",
        //     desc: "",
        //     image: require('../../assets/experiences/1.jpeg'),
        // },
        {
            title: "game drive",
            desc: "Explore Wildlife in its Natural Splendor and get to see wildlife.",
            image: require('../../assets/experiences/7.jpeg'),
        },
        {
            title: "bonfire",
            desc: "Gather round the Flames for Warmth and Camaraderie, sharing memories",
            image: require('../../assets/experiences/6.jpeg'),
        },
        // {
        //     title: "photoshoot",
        //     desc: "",
        //     image: ""
        // },
        // {
        //     title: "conferencing",
        //     desc: "",
        //     image: ""
        // },
        {
            title: "romantic gateway",
            desc: "Discover Love and Serenity Hand in Hand",
            image: require('../../assets/experiences/5.jpeg'),
        },
        {
            title: "nature walk",
            desc: "A Journey of Discovery through the Natural World",
            image: require('../../assets/experiences/8.jpeg'),
        },
    ]
    return <div className='activities-parent snap-item' id="activities-parent">
        <h1>Activities</h1>
        <p>The beauty of life is based on the memories you create.</p>
        <div className="carousel">
            {activities.map((item, index) => {
                return <div className="item" key={index}>
                    <div className="image" style={{backgroundImage:`url(${item?.image})`}}></div>
                    <div className="title">{item.title}</div>
                    <div className="description">{item.desc} </div>
                    {/* <div className="view-more">view more</div> */}
                </div>
            })}
        </div>

    </div>
}
export default LandingActivities;