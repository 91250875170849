// import { Buttons, TextInput } from '../shared/components';
import './admin.scss'
import AdminDashboard from './admin/dashboard';
import { RxChatBubble, RxDashboard, RxImage } from 'react-icons/rx';
import { FiHome, FiImage, FiLogOut, FiMessageSquare, FiPower, FiSettings } from 'react-icons/fi';
import { BiMessageSquareDots, BiSolidShield, BiSolidShieldAlt2 } from 'react-icons/bi';
import { TbShield, TbUserShield } from 'react-icons/tb';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const RouteAdmin = () => {
    const location = useLocation();
    return <div className="admin-parent">
        <div className='left'>
            {/* <div className='logo'></div> */}
            <div className='admin-title'><BiSolidShieldAlt2 size={17} style={{ marginRight: 5 }} /> ADMIN</div>
            <ul>
                <li className={`${location.pathname.includes("dashboard") ? 'active' : ''}`}>
                    <Link to={"/admin/dashboard"}>
                        <FiHome /> Dashboard
                    </Link>
                </li>
                <li className={`${location.pathname.includes("gallery") ? 'active' : ''}`}>
                    <Link to={"/admin/gallery"}>
                        <FiImage /> Gallery
                    </Link>
                </li>
                <li className={`${location.pathname.includes("messaging") ? 'active' : ''}`}>
                    <Link to={"/admin/messaging"}>
                        <BiMessageSquareDots />Messaging
                    </Link>

                </li>


                {/* <li><FiSettings />Settings</li> */}
                <li className='logout-bt'><a><FiLogOut />Logout</a></li>
            </ul>
        </div>
        <div className='right'>
            <div className='banner'></div>
            <div className='right-inner'>
                <Outlet />
            </div>
        </div>
    </div>
}
export default RouteAdmin;