import { Buttons, TextInput } from '../../shared/components';
import { useState } from 'react';
import { addDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';
import { firebaseApp } from '../../shared/firebase';

const LandingContact = () => {
    const firestore = getFirestore(firebaseApp);
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendMessage = async () => {
        if (userName.length < 1
            ||
            email.length < 1
            ||
            phoneNumber.length < 1
            ||
            message.length < 1
        ) {
            return alert("fill in all fields to proceed");
        }
        // setLoading2(true);
        try {
            const docRef = await addDoc(collection(firestore, "bookings"), {

                email,
                name: userName,
                message,
                // payment_status:"sent" ---> read only
                phone: phoneNumber,

                sent: new Date().toISOString()
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
            alert(e);
        }
    }
    return <div className='contact-parent snap-item' id="contact-parent">
        <div className='left-side'>
            <a href="https://maps.app.goo.gl/GdoSqMue8Bi2b56s9" target="_blank">
                <div className='map-still'>
                    <div className="icon">
                        <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 24 24" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-width="2" d="M17,11 L22,6 L17,1 M22,6 L18,6 C14.686,6 12,8.686 12,12 L12,24 M7,6 L2,11 L7,16 M2,11 L6,11 C9.314,11 12,13.686 12,17 L12,24"></path></svg>
                    </div>
                </div>
            </a>
        </div>
        <div className='right-side'>
            <h1>Contact Us</h1>
            <p className='mobile-hidden'>Have any queries or ideas concerning our facilities? Please leave us a message and we will be glad to respond ASAP.</p>
            <div className='row'>
                <TextInput.Grey placeholder={"First & Last Name"} onChange={(e) => setUserName(e.target.value)} />
                {/* <TextInput.Grey placeholder={"Last Name"} onChange={(e) => setLastName(e.target.value)} /> */}
            </div>
            <div className='row'>
                <TextInput.Grey placeholder={"Phone Number"} onChange={(e) => setPhoneNumber(e.target.value)} />
                <TextInput.Grey placeholder={"Email"} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='row'>
                <TextInput.GreyArea placeholder={"Message"} onChange={(e) => setMessage(e.target.value)} />
            </div>
            <div className='row'>
                <Buttons.Large text={"Send Message"} onClick={sendMessage} style={{ paddingTop: 20, paddingBottom: 20 }} />

            </div>
        </div>
    </div>
}
export default LandingContact;