import { FaChevronDown, FaChevronUp, FaMinus, FaPlus } from "react-icons/fa"
import { Calendar, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useState } from "react";
import { GetRandomStringOfLength, convertDateToReadable, getNowReadable, getPostDate, hyphenate } from "../../../shared/generalMethodology";
import { Link, useNavigate } from "react-router-dom";
import { Buttons } from "../../../shared/components";
import { RiCloseLine } from "react-icons/ri";

export const SquareMenu = ({ rangeShowing, setRangeShowing, dateShowing, setDateShowing }) => {
    const [startDate, setStartDate] = useState(convertDateToReadable(new Date()));
    const [endDate, setEndDate] = useState(convertDateToReadable(getPostDate(new Date(), 1)));
    const [roomA, setRoomA] = useState(1);
    const [roomB, setRoomB] = useState(0);
    const [roomSelectorVisible, setRoomSelectorVisible] = useState(false);
    const navigate = useNavigate();

    const handleSelect = (ranges) => {
        setStartDate(convertDateToReadable(new Date(ranges.selection.startDate)));
        setEndDate(convertDateToReadable(new Date(ranges.selection.endDate)));
        setRangeShowing(false);
        window.location.hash = "";
    }
    const handleDateSelect = (date) => {
        console.log(date);
        setEndDate(convertDateToReadable(new Date(date)));
        setDateShowing(false);
        window.location.hash = "";
    }
    const adjust = (room, direction) => {
        console.log([room, direction]);
        if (room === "A") {
            if (direction === "up") {
                setRoomA((prev) => prev + 1)
            }
            if (direction === "down") {
                setRoomA((prev) => {
                    if ((prev - 1) < 0) return 0;
                    return prev - 1
                })
            }
        }
        if (room === "B") {
            if (direction === "up") {
                setRoomB((prev) => prev + 1)
            }
            if (direction === "down") {
                setRoomB((prev) => {
                    if ((prev - 1) < 0) return 0;
                    return prev - 1
                })
            }
        }
    }

    useState(() => {
        const hashChangeHandler = () => {
            console.log("hash2", window.location.hash);
            if (window.location.hash.includes("checkin")) {
                setRangeShowing(true);
            } else {
                setRangeShowing(false);
            }
            if (window.location.hash.includes("checkout")) {
                setDateShowing(true);
            } else {
                setDateShowing(false);
            }
        }
        console.log("hash", ("onhashchange" in window))
        window.addEventListener('hashchange', hashChangeHandler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, [])
    return <>
        <div className="mobile-only">
            <div className="mobile-square-button-parent">
                <div className="row">
                    <div className='picker'>
                        <div onClick={() => {
                            console.log("click", rangeShowing);
                            window.location = "/#checkin-" + GetRandomStringOfLength(3);
                        }}>
                            <div>Checkin</div>
                            <div className='field'>{startDate} {rangeShowing ? <FaChevronDown className='ic' /> : <FaChevronUp className='ic' />}</div>
                        </div>

                        {rangeShowing
                            &&
                            <>
                                <div className="range-parent">
                                    <DateRange
                                        minDate={new Date()}
                                        ranges={[{
                                            startDate: new Date(startDate),
                                            endDate: new Date(endDate),
                                            key: 'selection',
                                        }]}
                                        onChange={handleSelect}
                                    />
                                </div>
                            </>

                        }
                    </div>
                    <div className='picker'>
                        <div onClick={() => {
                            // setDateShowing(!dateShowing);
                            // setRangeShowing(false);
                            window.location = "/#checkout-" + GetRandomStringOfLength(3);
                        }}>
                            <div>Checkout</div>
                            <div className='field'>{endDate} {dateShowing ? <FaChevronDown className='ic' /> : <FaChevronUp className='ic' />}</div>
                        </div>

                        {dateShowing
                            &&
                            <div className="calendar-parent">
                                <Calendar
                                    minDate={new Date(endDate)}
                                    date={new Date(endDate)}
                                    onChange={handleDateSelect}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className={`picker ${roomSelectorVisible ? 'picker-hidden' : ''}`}
                        onClick={() => {
                            setDateShowing(false);
                            setRangeShowing(false);
                            setRoomSelectorVisible(true);
                        }}
                    >
                        <div>Rooms</div>
                        <div className='field' style={{ textTransform: 'lowercase' }}>{roomA + roomB}  room(s)<FaChevronUp className='ic' /> </div>
                    </div>
                </div>
                {roomSelectorVisible && <>
                    <div className="room-selector-parent">
                        <div className={`room-selector room-selector-showing`}>
                            <div className="rs-title">Select Rooms</div>
                            <div className="close-bt"  onClick={() => setRoomSelectorVisible(false)}><RiCloseLine className='ic' /></div>
                            <div className="rs-row">
                                <div className="rs-type">kings</div>
                                <div className="rs-ranger">
                                    <div className="rs-button" onClick={() => adjust("A", "down")}><FaMinus /></div>
                                    <div className="rs-value">{roomA}</div>
                                    <div className="rs-button" onClick={() => adjust("A", "up")}><FaPlus /></div>
                                </div>
                            </div>
                            <div className="rs-row">
                                <div className="rs-type">excecutive</div>
                                <div className="rs-ranger">
                                    <div className="rs-button" onClick={() => adjust("B", "down")}><FaMinus /></div>
                                    <div className="rs-value">{roomB}</div>
                                    <div className="rs-button" onClick={() => adjust("B", "up")}><FaPlus /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }

                <div className="row">
                    <Link to={`/booking?checkin=${hyphenate(startDate)}&checkout=${hyphenate(endDate)}&A=${roomA}&B=${roomB}`}>
                        <Buttons.Large text={"Book Room"} />
                    </Link>
                </div>

            </div>
        </div>
        <div className='square-button-parent'>
            <div className='square-button'>Booking Details</div>
            <div className='square-button'>
                <div className='picker'>
                    <div onClick={() => {
                        setRangeShowing(!rangeShowing);
                        setDateShowing(false);
                        console.log("click", rangeShowing);
                    }}>
                        <div>Checkin</div>
                        <div className='field'>{startDate} {rangeShowing ? <FaChevronDown className='ic' /> : <FaChevronUp className='ic' />}</div>
                    </div>

                    {rangeShowing
                        &&
                        <div className="range-parent">
                            <DateRange
                                minDate={new Date()}
                                ranges={[{
                                    startDate: new Date(startDate),
                                    endDate: new Date(endDate),
                                    key: 'selection',
                                }]}
                                onChange={handleSelect}
                            />
                        </div>
                    }

                </div>
            </div>
            <div className='square-button'>
                <div className='picker'>
                    <div onClick={() => {
                        setDateShowing(!dateShowing);
                        setRangeShowing(false);
                    }}>
                        <div>Checkout</div>
                        <div className='field'>{endDate} {dateShowing ? <FaChevronDown className='ic' /> : <FaChevronUp className='ic' />}</div>
                    </div>

                    {dateShowing
                        &&
                        <div className="calendar-parent">
                            <Calendar
                                minDate={new Date(endDate)}
                                date={new Date(endDate)}
                                onChange={handleDateSelect}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className={`square-button rooms-square`}>
                <div className={`picker ${roomSelectorVisible ? 'picker-hidden' : ''}`}
                    onClick={() => {
                        setDateShowing(false);
                        setRangeShowing(false);
                        setRoomSelectorVisible(true);
                    }}
                >
                    <div>Rooms</div>
                    <div className='field' style={{ textTransform: 'lowercase' }}>{roomA + roomB}  room(s)<FaChevronUp className='ic' /> </div>
                </div>

                <div className={`room-selector ${roomSelectorVisible ? 'room-selector-showing' : ''}`}>
                    <div className="rs-title" onClick={() => setRoomSelectorVisible(false)}>Select Rooms<FaChevronDown className='ic' /></div>
                    <div className="rs-row">
                        <div className="rs-type">kings</div>
                        <div className="rs-ranger">
                            <div className="rs-button" onClick={() => adjust("A", "down")}><FaMinus /></div>
                            <div className="rs-value">{roomA}</div>
                            <div className="rs-button" onClick={() => adjust("A", "up")}><FaPlus /></div>
                        </div>
                    </div>
                    <div className="rs-row">
                        <div className="rs-type">excecutive</div>
                        <div className="rs-ranger">
                            <div className="rs-button" onClick={() => adjust("B", "down")}><FaMinus /></div>
                            <div className="rs-value">{roomB}</div>
                            <div className="rs-button" onClick={() => adjust("B", "up")}><FaPlus /></div>
                        </div>
                    </div>
                </div>

            </div>
            <Link to={`/booking?checkin=${hyphenate(startDate)}&checkout=${hyphenate(endDate)}&A=${roomA}&B=${roomB}`} className='square-button'>
                <div>Book Now</div>
            </Link>
        </div>
    </>



}